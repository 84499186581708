.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.App-footer {
  font-size: 10pt;
}

.segment-inspector {
  width: 80%;
}

.red {
  color: red;
}

.small {
  font-size: 8pt;
}

.metadata {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 12pt;
  border: 0.5px solid;
}

label {
  font-size: 10pt;
  font-weight: bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mp4boxes {
  overflow-y: hidden;
}

.mp4box {
  text-align: left;
  font-family: 'Courier New', Courier, monospace;
  font-size: 10pt;
  border: 1px solid;
  margin-bottom: 2px;
  margin-right: 2px;
}

.mp4box-children {
  margin-left: 20px;
}

.mp4box-children-collapsed {
  height: 0px;
}

.mp4box-noarrow {
  margin-left: 2px;
  margin-right: 6px;
  display: inline-block;
}

.mp4box-noarrow:after {
  content: '-';
}

.mp4box-arrow {
  cursor: pointer;
  margin-left: 2px;
  margin-right: 6px;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mp4box-arrow:after {
  content: '▾';
}

.mp4box-arrow-collapsed {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.mp4info-table {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 10pt;
  border: 0.5px solid;  
}

.files-dropzone {
  font-size: 10pt;
  border: 1px dashed;
  border-color: black;
  padding: 20px;
  cursor: pointer;
}

.files-dropzone-active {
  border-color: blue;
  border: 2px dashed;
}